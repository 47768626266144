import React from "react";
import {
	useAcademiaFormState,
	ACADEMIA_FORM_STEPS,
} from "../_useAcademiaFormState";
import { AcademiaStepPage } from "../_AcademiaStepPage";
import { useApplicationUsePageFields } from "@components/ApplicationStepPage/common/Use/useApplicationUsePageFields";

const AcademiaUse: React.FunctionComponent = () => {
	const formData = useAcademiaFormState(ACADEMIA_FORM_STEPS.USE.formStep);
	const { body, onSubmit } = useApplicationUsePageFields(formData);

	return (
		<AcademiaStepPage
			currentStepIndex={ACADEMIA_FORM_STEPS.USE.formStep}
			onButtonClick={() =>
				onSubmit(() =>
					formData.navigateToStep(
						ACADEMIA_FORM_STEPS.SUMMARY.formStep
					)
				)
			}
			onBackClick={() =>
				formData.navigateToStep(ACADEMIA_FORM_STEPS.TEAM.formStep)
			}
		>
			{body}
		</AcademiaStepPage>
	);
};

export default AcademiaUse;
